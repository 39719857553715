import { toaster } from 'components/v2/toast';
import { MAX_IMG_SIZE, REMOVE_FILE } from 'constants/constants';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import {
  FileParameter,
  ProductClassificationModel,
  RegisterEditorialModel,
  UpdateEPDPropertyModel,
} from 'services/EpdClient';
import EpdService from 'services/EpdService';
import {
  AddEPDFileModel,
  AddEPDPropertyModel,
  CO2UptakeAssociationModelWithParams,
  ConnectDeveloperModel,
  ConnectVerifierModel,
  ConversionFactorModel,
  CreateEPDProductionSiteAddressModel,
  DeclarationOfCO2AssumptionModelWithParams,
  DictionaryCatalogItem,
  DictionaryValue,
  EPDBestOrWorstProductModel,
  EPDContentDeclarationProductType,
  EPDDataSourceModel,
  EPDProductInformationModel,
  ElectricityDataModel,
  FoundationAddressModel,
  IVersionedEntity,
  LCASpecificationModel,
  LcaResultModel,
  ProductComponent,
  ProductDangerMaterial,
  ProductPackagingMaterial,
  ProductPanelContent,
  ReferenceFlowModel,
  RequestCollaborationModel,
  ScenarioItemModel,
  ScenarioModuleDefaultDescriptionModel,
  ScenarioModuleItem,
  ScenarioModuleRequest,
  ScrapInputModel,
  ShareOfTotalScrapInputModel,
  TransportationPerformanceModel,
  UpdateEPDProductInformationPropertyModel,
} from 'types/types';

import { MutationFunction, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

import { QUERY_KEYS } from './constants';
import {
  addEPDFile,
  addEPDProductInformationFile,
  approveEditorial,
  approveVerification,
  cancelEpdDeregistration,
  cancelRegisterEditorial,
  cancelUpdateEpd,
  cancelVerification,
  connectCertificateIssuedByBody,
  connectDeveloper,
  connectVerifier,
  copyEpdScenario,
  copyProductInformation,
  createCpcrSpecificTableRow,
  createDictionary,
  createDictionaryValue,
  createEPDProductClassification,
  createEpdCO2UptakeAssociation,
  createEpdDataSource,
  createEpdDeclarationOfCO2Assumption,
  createEpdDefaultScenarioModuleDescription,
  createEpdLcaResultAdditional,
  createEpdProductComponent,
  createEpdProductDangerMaterial,
  createEpdProductInformation,
  createEpdProductPackagingMaterial,
  createEpdProductPanelContent,
  createEpdScenario,
  createEpdScenarioModule,
  createEpdScrapInput,
  createEpdShareOfTotalScrapInput,
  createProductionSiteAddress,
  createTag,
  deleteAddress,
  deleteCpcrSpecificDataRow,
  deleteDictionaryValue,
  deleteEpdDataSource,
  deleteEpdLcaResultAdditional,
  deleteEpdProductComponent,
  deleteEpdProductDangerMaterial,
  deleteEpdProductPackagingMaterial,
  deleteEpdProductPanelContent,
  deleteEpdScenario,
  deleteEpdScenarioModule,
  deleteEpdScrapInput,
  deleteProductClassification,
  deleteProductInformation,
  deregisterEpd,
  editorialUpdating,
  licenseeAdminVerificationApprove,
  licenseeAdminVerificationDecline,
  needsVerification,
  parseAndSaveLCAFile,
  publishEpd,
  registerEditorial,
  registerEpd,
  removeEPDFile,
  requestCollaboration,
  startVerification,
  unlockEpd,
  unpublishEpd,
  updateAddress,
  updateCpcrSpecificTableColumn,
  updateCpcrSpecificTableRow,
  updateDictionary,
  updateDictionaryValue,
  updateElectricity,
  updateEpdCO2UptakeAssociation,
  updateEpdDataSource,
  updateEpdDeclarationOfCO2Assumption,
  updateEpdDefaultScenarioModuleDescription,
  updateEpdLcaResultAdditional,
  updateEpdProductComponent,
  updateEpdProductDangerMaterial,
  updateEpdProductInformation,
  updateEpdProductPackagingMaterial,
  updateEpdProductPanelContent,
  updateEpdProperty,
  updateEpdPropertyWithMutation,
  updateEpdScenario,
  updateEpdScenarioModule,
  updateEpdScrapInput,
  updateEpdShareOfTotalScrapInput,
  updateEpdSystemBoundaryGridModuleDeclared,
  updateEpdSystemBoundaryGridModuleGeography,
  updateEpdTransportationPerformance,
  updateProductClassification,
  upsertConversionFactor,
  upsertEpdLcaResult,
  upsertLCASpecification,
  upsertReferenceFlow,
  updateEpdContentDeclarationProduct,
} from './epdApi';

export function isOfType<T>(value: any): value is T {
  return typeof value === 'object' && value !== null;
}

const optimisticMutation = (mutationFn: any, queryKey: any) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: mutationFn,
    onMutate: async (variables: any) => {
      await queryClient.cancelQueries({ queryKey });
      const prevData = queryClient.getQueryData<any>(queryKey);
      let newData = Array.isArray(prevData)
        ? prevData.map((prevItem) => {
            return prevItem.id === variables.id ? { ...prevItem, ...variables } : prevItem;
          })
        : isOfType<UpdateEPDPropertyModel>(variables)
        ? { ...prevData, [(variables as UpdateEPDPropertyModel).propertyName!]: variables.newValue }
        : { ...prevData, ...variables };

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, newData);
      return { prevData, newData };
    },
    onError: (error, context, variables) => {
      queryClient.setQueryData(queryKey, context.prevData);
      // TODO server error?
      let newValue = Array.isArray(variables?.newData)
        ? variables?.newData.find((obj: any) => obj.hasOwnProperty('fieldToUpdateValue')).fieldToUpdateValue
        : variables?.newData.fieldToUpdateValue;

      toaster({
        severity: 'error',
        summary: 'Error',
        details: newValue ? `Data update error with value: ${newValue}` : `Data update error`,
      });
      console.error(error);
    },
    onSuccess: () => {},
    onSettled: async (_, error, variables) => {
      if (variables.newValue instanceof File) return;

      await queryClient.invalidateQueries({ queryKey: queryKey });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
    },
  });
};

export const useUpdateEpdGeneralInformation = (epdVersionId: string) => {
  return optimisticMutation(
    (data: UpdateEPDPropertyModel) => updateEpdProperty(data),
    [QUERY_KEYS.EPD_GENERAL_INFORMATION, epdVersionId]
  );
};

export const useUpdateContentDeclaationProduct = (epdVersionId: string) => {
  return optimisticMutation(
    (data: EPDBestOrWorstProductModel) => updateEpdContentDeclarationProduct(data),
    [QUERY_KEYS.BEST_AND_WORST_PRODUCT, epdVersionId]
  );
};

export const useUpdateEpdOrganizationInformation = (epdVersionId: string) => {
  const queryClient = useQueryClient();
  return optimisticMutation(
    (data: UpdateEPDPropertyModel) =>
      onMutateEpd(data, [QUERY_KEYS.EPD_ORGANIZATION_INFORMATION, epdVersionId], queryClient),
    [QUERY_KEYS.EPD_ORGANIZATION_INFORMATION, epdVersionId]
  );
};

export const useAddOrganizationInformationFile = (epdVersionId: string) => {
  return optimisticMutation((data: FormData) => addEPDFile(data), [QUERY_KEYS.EPD_ORGANIZATION_INFORMATION, epdVersionId]);
};

export const useRemoveOrganizationInformationFile = (epdVersionId: string) => {
  return optimisticMutation(
    (id: string) => removeEPDFile(epdVersionId, id),
    [QUERY_KEYS.EPD_ORGANIZATION_INFORMATION, epdVersionId]
  );
};

export const useUpdateEpdOrganizationInformationAddress = (epdVersionId: string) => {
  return optimisticMutation(
    (data: UpdateEPDPropertyModel) => updateEpdProperty(data),
    [QUERY_KEYS.EPD_ORGANIZATION_INFORMATION_ADDRESS, epdVersionId]
  );
};

export const useUpdateEpdData = (epdId: string) => {
  return optimisticMutation((data: UpdateEPDPropertyModel) => updateEpdProperty(data), [QUERY_KEYS.EPD, epdId]);
};

export const useUpdateEpdProductComponent = (epdVersionId: string, type: EPDContentDeclarationProductType) => {
  let queryKey = QUERY_KEYS.EPD_PRODUCT_COMPONENTS;
  if (type === EPDContentDeclarationProductType.BestProduct) {
    queryKey = QUERY_KEYS.EPD_BEST_PRODUCT_COMPONENTS;
  }
  return optimisticMutation(
    (data: ProductComponent) => updateEpdProductComponent(data),
    [QUERY_KEYS.EPD_PRODUCT_COMPONENTS, epdVersionId]
  );
};

export const useUpdateEpdProductInformation = (epdVersionId: string) => {
  return optimisticMutation(
    (data: UpdateEPDProductInformationPropertyModel) => updateEpdProductInformation(data),
    [QUERY_KEYS.EPD_PRODUCT_INFORMATION, epdVersionId]
  );
};

export const useAddProductInformationFileMutation = (epdVersionId: string) => {
  return optimisticMutation(
    (data: FormData) => addEPDProductInformationFile(epdVersionId, data),
    [QUERY_KEYS.EPD_PRODUCT_INFORMATION, epdVersionId]
  );
};

export const useRemoveProductInformationFileMutation = (epdVersionId: string) => {
  return optimisticMutation(
    (id: string) => removeEPDFile(epdVersionId, id),
    [QUERY_KEYS.EPD_PRODUCT_INFORMATION, epdVersionId]
  );
};

export const useCreateEpdProductInformation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: EPDProductInformationModel) => createEpdProductInformation(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useDeleteProductInformation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteProductInformation(id),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useCopyProductInformation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: IVersionedEntity) => copyProductInformation(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
      }
    },
  });
};

export const useDeleteProductClassification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteProductClassification(id),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useCreateProductClassification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ProductClassificationModel) => createEPDProductClassification(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useCreateEpdProductionSiteAddress = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateEPDProductionSiteAddressModel) => createProductionSiteAddress(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useUpdateProductClassification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ProductClassificationModel) => updateProductClassification(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useUpdateAddress = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: FoundationAddressModel) => updateAddress(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useDeleteAddress = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteAddress(id),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useCreateEpdProductComponent = (type: EPDContentDeclarationProductType) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ProductComponent) => createEpdProductComponent(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        let queryKey = QUERY_KEYS.EPD_PRODUCT_COMPONENTS;
        if (type === EPDContentDeclarationProductType.BestProduct) {
          queryKey = QUERY_KEYS.EPD_BEST_PRODUCT_COMPONENTS;
        }

        await queryClient.invalidateQueries({ queryKey: [queryKey] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useDeleteProductComponent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteEpdProductComponent(id),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        let queryKey = QUERY_KEYS.EPD_PRODUCT_COMPONENTS;

        await queryClient.invalidateQueries({ queryKey: [queryKey] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

// Packaging materials
export const useUpdateEpdProductPackagingMaterial = (epdVersionId: string, type: EPDContentDeclarationProductType) => {
  let queryKey = QUERY_KEYS.EPD_PRODUCT_PACKAGING_MATERIALS;
  if (type === EPDContentDeclarationProductType.BestProduct) {
    queryKey = QUERY_KEYS.EPD_BEST_PRODUCT_PACKAGING_MATERIALS;
  }

  return optimisticMutation(
    (data: ProductPackagingMaterial) => updateEpdProductPackagingMaterial(data),
    [queryKey, epdVersionId]
  );
};

export const useCreateEpdProductPackagingMaterial = (type: EPDContentDeclarationProductType) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ProductPackagingMaterial) => createEpdProductPackagingMaterial(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        let queryKey = QUERY_KEYS.EPD_PRODUCT_PACKAGING_MATERIALS;
        if (type === EPDContentDeclarationProductType.BestProduct) {
          queryKey = QUERY_KEYS.EPD_BEST_PRODUCT_PACKAGING_MATERIALS;
        }

        await queryClient.invalidateQueries({ queryKey: [queryKey] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useDeleteProductPackagingMaterial = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteEpdProductPackagingMaterial(id),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        let queryKey = QUERY_KEYS.EPD_PRODUCT_PACKAGING_MATERIALS;

        await queryClient.invalidateQueries({ queryKey: [queryKey] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

// Panel content
export const useUpdateEpdProductPanelContent = (epdVersionId: string, type: EPDContentDeclarationProductType) => {
  let queryKey = QUERY_KEYS.EPD_PRODUCT_PANEL_CONTENT;
  if (type === EPDContentDeclarationProductType.BestProduct) {
    queryKey = QUERY_KEYS.EPD_BEST_PRODUCT_PANEL_CONTENT;
  }
  return optimisticMutation((data: ProductPanelContent) => updateEpdProductPanelContent(data), [queryKey, epdVersionId]);
};

export const useCreateEpdProductPanelContent = (type: EPDContentDeclarationProductType) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ProductPanelContent) => createEpdProductPanelContent(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        let queryKey = QUERY_KEYS.EPD_PRODUCT_PANEL_CONTENT;
        if (type === EPDContentDeclarationProductType.BestProduct) {
          queryKey = QUERY_KEYS.EPD_BEST_PRODUCT_PANEL_CONTENT;
        }

        await queryClient.invalidateQueries({ queryKey: [queryKey] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useDeleteProductPanelContent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteEpdProductPanelContent(id),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        let queryKey = QUERY_KEYS.EPD_PRODUCT_PANEL_CONTENT;

        await queryClient.invalidateQueries({ queryKey: [queryKey] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

// Danger materials
export const useUpdateEpdProductDangerMaterial = (epdVersionId: string) => {
  return optimisticMutation(
    (data: ProductDangerMaterial) => updateEpdProductDangerMaterial(data),
    [QUERY_KEYS.EPD_PRODUCT_DANGER_MATERIALS, epdVersionId]
  );
};

export const useCreateEpdProductDangerMaterial = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ProductDangerMaterial) => createEpdProductDangerMaterial(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_DANGER_MATERIALS] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useDeleteProductDangerMaterial = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteEpdProductDangerMaterial(id),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_DANGER_MATERIALS] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

// Electricity
export const useUpdateEpdElectricity = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ElectricityDataModel) => updateElectricity(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ELECTRICITY] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

// Scenarios
export const useCreateEpdScenario = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ScenarioItemModel) => createEpdScenario(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SCENARIOS] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useUpdateEpdScenario = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ScenarioItemModel) => updateEpdScenario(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SCENARIOS] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useDeleteEpdScenario = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteEpdScenario(id),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SCENARIOS] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useCopyEpdScenario = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: IVersionedEntity) => copyEpdScenario(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SCENARIOS] });
      }
    },
  });
};

// Scenario modules
export const useCreateEpdScenarioModule = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: ScenarioModuleRequest) => createEpdScenarioModule(request),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_MODULE] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useUpdateEpdScenarioModule = (epdVersionId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ScenarioModuleItem) => updateEpdScenarioModule(epdVersionId, data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_MODULE] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useDeleteEpdScenarioModule = (epdVersionId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteEpdScenarioModule(epdVersionId, id),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_MODULE] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useCreateEpdScenarioModuleDefaultDescription = () => {
  return optimisticMutation(
    (data: ScenarioModuleDefaultDescriptionModel) => createEpdDefaultScenarioModuleDescription(data),
    [QUERY_KEYS.EPD_MODULE]
  );
};

export const useUpdateEpdScenarioModuleDefaultDescription = () => {
  return optimisticMutation(
    (data: ScenarioModuleDefaultDescriptionModel) => updateEpdDefaultScenarioModuleDescription(data),
    [QUERY_KEYS.EPD_MODULE]
  );
};

export const useUpsertEpdLcaResult = () => {
  return optimisticMutation((data: LcaResultModel) => upsertEpdLcaResult(data), [QUERY_KEYS.EPD_LCA_RESULTS]);
};

export const useChangeEPDDefinition = (epdVersionId: string) => {
  // return optimisticMutation(
  //   (data: AddEPDPropertyModel) => updateEpdPropertyWithMutation(data),
  //   [QUERY_KEYS.EPD_DEFINITION, epdVersionId]
  // );
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: AddEPDPropertyModel) => updateEpdPropertyWithMutation(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await Promise.all([
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DEFINITION] }),
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TAG_SOURCE] }),
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_GENERAL_INFORMATION] }),
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] }),
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] }),
        ]);
      }
    },
  });
};

export const useChangeEPDVerificationReport = (epdVersionId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: AddEPDPropertyModel) => updateEpdPropertyWithMutation(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await Promise.all([
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_VERIFICATION_REPORT_INFO] }),
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] }),
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] }),
        ]);
      }
    },
  });
};

export const useAddEpdLcaResultFile = (epdVersionId: string) => {
  //return optimisticMutation((data: FormData) => addEPDFile(data), [QUERY_KEYS.EPD_LCA_RESULTS, epdVersionId]);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: FormData) => addEPDFile(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_LCA_RESULTS_ADDITIONAL] });
        await parseAndSaveLCAFile(epdVersionId);
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_LCA_RESULTS] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useAddEpdVerificationReportFile = (epdVersionId: string) => {
  return optimisticMutation((data: FormData) => addEPDFile(data), [QUERY_KEYS.EPD_VERIFICATION_REPORT_INFO, epdVersionId]);
};

export const useRemoveEpdLcaResultFile = (epdVersionId: string) => {
  return optimisticMutation((id: string) => removeEPDFile(epdVersionId, id), [QUERY_KEYS.EPD_LCA_RESULTS, epdVersionId]);
};

export const useRemoveEpdVerificationReportFile = (epdVersionId: string) => {
  return optimisticMutation(
    (id: string) => removeEPDFile(epdVersionId, id),
    [QUERY_KEYS.EPD_VERIFICATION_REPORT_INFO, epdVersionId]
  );
};

// LCA Results additional
export const useCreateEpdLcaResultAdditional = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: LcaResultModel) => createEpdLcaResultAdditional(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_LCA_RESULTS_ADDITIONAL] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useCreateTag = (epdVersionId: string) => {
  return optimisticMutation((data: any) => createTag(data), [QUERY_KEYS.TAG_SOURCE]);
};

export const useUpdateEpdLcaResultAdditional = (epdVersionId: string) => {
  return optimisticMutation(
    (data: LcaResultModel) => updateEpdLcaResultAdditional(data),
    [QUERY_KEYS.EPD_LCA_RESULTS_ADDITIONAL, epdVersionId]
  );
};

export const useDeleteEpdLcaResultAdditional = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteEpdLcaResultAdditional(id),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_LCA_RESULTS_ADDITIONAL] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

// Declaration of CO2
export const useCreateEpdDeclarationOfCO2Assumption = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: DeclarationOfCO2AssumptionModelWithParams) =>
      createEpdDeclarationOfCO2Assumption(data.epdVersionId, data.nameId, {
        assumptions: data.assumptions,
        isSelected: data.isSelected,
      }),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DECLARATIONS_OF_CO2] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useUpdateEpdDeclarationOfCO2Assumption = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: DeclarationOfCO2AssumptionModelWithParams) =>
      updateEpdDeclarationOfCO2Assumption(data.epdVersionId, data.nameId, {
        assumptions: data.assumptions,
        isSelected: data.isSelected,
      }),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DECLARATIONS_OF_CO2] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useCreateEpdCO2UptakeAssociation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CO2UptakeAssociationModelWithParams) =>
      createEpdCO2UptakeAssociation(data.epdVersionId, {
        association: data.association,
      }),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_CO2_UPTAKE_ASSOCIATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useUpdateEpdCO2UptakeAssociation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CO2UptakeAssociationModelWithParams) =>
      updateEpdCO2UptakeAssociation(data.epdVersionId, { id: data.id, association: data.association }),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_CO2_UPTAKE_ASSOCIATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useUpsertLCASpecification = (epdVersionId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: LCASpecificationModel) => upsertLCASpecification(epdVersionId, data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_LCA_SPECIFICATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useUpsertEpdReferenceFlow = (epdVersionId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ReferenceFlowModel) => upsertReferenceFlow(epdVersionId, data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_REFERENCE_FLOW] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useUpsertEpdConversionFactor = (epdVersionId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ConversionFactorModel) => upsertConversionFactor(epdVersionId, data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_CONVERSION_FACTOR] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useCreateEpdDataSource = (epdVersionId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: EPDDataSourceModel) => createEpdDataSource(epdVersionId, data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DATA_SOURCES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useUpdateEpdDataSource = (epdVersionId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: EPDDataSourceModel) => updateEpdDataSource(epdVersionId, data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DATA_SOURCES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useDeleteEpdDataSource = (epdVersionId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteEpdDataSource(id, epdVersionId),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DATA_SOURCES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

// Contribution of scrap inputs
export const useCreateEpdScrapInput = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ScrapInputModel) => createEpdScrapInput(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SCRAP_INPUTS] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useUpdateEpdScrapInput = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ScrapInputModel) => updateEpdScrapInput(data),
    onMutate: () => {},
    onError: (e: any) => {
      console.error(e);
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SCRAP_INPUTS] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useDeleteEpdScrapInput = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteEpdScrapInput(id),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SCRAP_INPUTS] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useCreateEpdShareOfTotalScrapInput = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ShareOfTotalScrapInputModel) => createEpdShareOfTotalScrapInput(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SHARE_OF_TOTAL_SCRAP_INPUT] });
      }
    },
  });
};

export const useUpdateEpdShareOfTotalScrapInput = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ShareOfTotalScrapInputModel) => updateEpdShareOfTotalScrapInput(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SHARE_OF_TOTAL_SCRAP_INPUT] });
      }
    },
  });
};

export const useUpdateEpdSystemBoundaryModuleDeclared = (epdVersionId: string) => {
  return optimisticMutation(
    (data: { epdVersionId: string; fieldName: string; value: string }) => updateEpdSystemBoundaryGridModuleDeclared(data),
    [QUERY_KEYS.EPD_LCA_DECLARED_MODULES, epdVersionId]
  );
};

export const useUpdateEpdSystemBoundaryModuleGeography = (epdVersionId: string) => {
  return optimisticMutation(
    (data: { epdVersionId: string; fieldName: string; value: string }) => updateEpdSystemBoundaryGridModuleGeography(data),
    [QUERY_KEYS.EPD_LCA_DECLARED_MODULES, epdVersionId]
  );
};

export const useUpdateEpdTransportationPerformance = (epdVersionId: string) => {
  return optimisticMutation(
    (data: TransportationPerformanceModel) => updateEpdTransportationPerformance(data),
    [QUERY_KEYS.EPD_LCA_TRANSPORTATION, epdVersionId]
  );
};

export const useUpdateSystemBoundaryMutation = (epdVersionId: string) => {
  const queryClient = useQueryClient();
  return optimisticMutation(
    (data: UpdateEPDPropertyModel) => onMutateEpd(data, [QUERY_KEYS.EPD_SYSTEM_BOUNDARY, epdVersionId], queryClient),
    [QUERY_KEYS.EPD_SYSTEM_BOUNDARY, epdVersionId]
  );
};

export const useAddSystemBoundaryFileMutation = (epdVersionId: string) => {
  return optimisticMutation((data: FormData) => addEPDFile(data), [QUERY_KEYS.EPD_SYSTEM_BOUNDARY, epdVersionId]);
};

export const useRemoveSystemBoundaryFileMutation = (epdVersionId: string) => {
  return optimisticMutation((id: string) => removeEPDFile(epdVersionId, id), [QUERY_KEYS.EPD_SYSTEM_BOUNDARY, epdVersionId]);
};

export const useUpdateCoverPageMutation = (epdVersionId: string) => {
  const queryClient = useQueryClient();
  return optimisticMutation(
    (data: UpdateEPDPropertyModel) => onMutateEpd(data, [QUERY_KEYS.EPD_COVER_PAGE_INFORMATION, epdVersionId], queryClient),
    [QUERY_KEYS.EPD_COVER_PAGE_INFORMATION, epdVersionId]
  );
};

export const useAddCoverPageFile = (epdVersionId: string) => {
  return optimisticMutation((data: FormData) => addEPDFile(data), [QUERY_KEYS.EPD_COVER_PAGE_INFORMATION, epdVersionId]);
};

export const useRemoveCoverPageFile = (epdVersionId: string) => {
  return optimisticMutation(
    (id: string) => removeEPDFile(epdVersionId, id),
    [QUERY_KEYS.EPD_COVER_PAGE_INFORMATION, epdVersionId]
  );
};

export const useUpdateEpdPCRInformation = (epdVersionId: string) => {
  return optimisticMutation(
    (data: UpdateEPDPropertyModel) => updateEpdProperty(data),
    [QUERY_KEYS.EPD_PCR_INFORMATION, epdVersionId]
  );
};

export const useUpdateEpdLcaSupport = (epdVersionId: string) => {
  return optimisticMutation(
    (data: UpdateEPDPropertyModel) => updateEpdProperty(data),
    [QUERY_KEYS.EPD_LCA_SUPPORT, epdVersionId]
  );
};

export const useUpdateEpdThirdPartyVerification = (epdVersionId: string) => {
  return optimisticMutation(
    (data: UpdateEPDPropertyModel) => updateEpdProperty(data),
    [QUERY_KEYS.EPD_THIRD_PARTY_VERIFICATION, epdVersionId]
  );
};

export const useUpdateEpdAdditionalInformation = (epdVersionId: string) => {
  return optimisticMutation(
    (data: UpdateEPDPropertyModel) => updateEpdProperty(data),
    [QUERY_KEYS.EPD_ADDITIONAL_INFORMATION, epdVersionId]
  );
};

export const useConnectEpdLcaPractitioner = (epdVersionId: string) => {
  return optimisticMutation(
    (data: ConnectDeveloperModel) => connectDeveloper(data),
    [QUERY_KEYS.EPD_LCA_SUPPORT, epdVersionId]
  );
};

export const useConnectEpdIndividualVerifier = (epdVersionId: string) => {
  return optimisticMutation(
    (data: ConnectVerifierModel) => connectVerifier(data),
    [QUERY_KEYS.EPD_THIRD_PARTY_VERIFICATION, epdVersionId]
  );
};

export const useConnectEpdCertificationBody = (epdVersionId: string) => {
  return optimisticMutation(
    (data: any) => connectCertificateIssuedByBody(data),
    [QUERY_KEYS.EPD_THIRD_PARTY_VERIFICATION, epdVersionId]
  );
};

export const useRequestCollaboration = (epdVersionId: string) => {
  return optimisticMutation(
    (data: RequestCollaborationModel) => requestCollaboration(data),
    [QUERY_KEYS.EPD_THIRD_PARTY_VERIFICATION, epdVersionId]
  );
};

export const onMutateEpd = async (data: UpdateEPDPropertyModel, queryKey: any, queryClient: any) => {
  if (data.propertyName === REMOVE_FILE) {
    await EpdService.removeEpdFile(data.newValue, data.versionId!);
    return;
  }

  await updateEpdProperty(data);
};

export const useAddEPDProcessCertificate = (epdVersionId: string) => {
  return optimisticMutation((data: FormData) => addEPDFile(data), [QUERY_KEYS.EPD_THIRD_PARTY_VERIFICATION, epdVersionId]);
};

export const useRemoveEPDProcessCertificate = (epdVersionId: string) => {
  return optimisticMutation(
    (id: string) => removeEPDFile(epdVersionId, id),
    [QUERY_KEYS.EPD_THIRD_PARTY_VERIFICATION, epdVersionId]
  );
};

export const useUpdateLCAPractitioner = (epdVersionId: string) => {
  return optimisticMutation(
    (data: UpdateEPDPropertyModel) => updateEpdProperty(data),
    [QUERY_KEYS.EPD_LCA_SUPPORT, epdVersionId]
  );
};

export const useAddEpdDocument = (epdId: string) => {
  return optimisticMutation((data: FormData) => addEPDFile(data), [QUERY_KEYS.EPD, epdId]);
};

export const useRemoveEpdDocument = (epdId: string, epdVersionId: string) => {
  return optimisticMutation((id: string) => removeEPDFile(epdVersionId, id), [QUERY_KEYS.EPD, epdId]);
};

export const addEpdFile = async (data: AddEPDFileModel, mutation: UseMutationResult<any, Error, any, any>) => {
  if (!data) {
    throw new Error('Required data parameter must not be null.');
  }

  const { epdId, propertyName, file, epdProductInformationId } = data;

  if (!epdId || !propertyName || !file) {
    throw new Error('Parameters epdId, propertyName, file must be specified.');
  }

  const fileName = file.name;
  const fileType = file.type;

  if (fileType.startsWith('image/') && file.size > MAX_IMG_SIZE) {
    toaster({
      severity: 'error',
      summary: 'Error',
      details: t('epdWizard.messages.errorTooLargeImageFile', {
        size: Math.floor(MAX_IMG_SIZE / (1024 * 1024)),
      }) as string,
    });

    return;
  }

  const reader = new FileReader();
  reader.onload = async () => {
    const fileBlob = reader.result as ArrayBuffer;
    if (fileBlob) {
      const blob = new Blob([new Uint8Array(fileBlob)], { type: fileType });
      if (blob.size > 10000000) {
        toast.error(t('epdWizard.messages.errorTooLargeFile') as string, {
          position: 'top-center',
        });
        return;
      }

      try {
        const addFileModel: FileParameter = { fileName, data: blob };
        const addFileFormData = new FormData();

        addFileFormData.append('EPDId', epdId.toString());
        addFileFormData.append('PropertyName', propertyName.toString());
        addFileFormData.append('NewValue', addFileModel.data, addFileModel.fileName ? addFileModel.fileName : 'NewValue');

        if (!!epdProductInformationId) {
          addFileFormData.append('EPDProductInformationId', epdProductInformationId.toString());
        }

        await mutation.mutateAsync(addFileFormData);
      } catch {
        toaster({
          severity: 'error',
          summary: 'Error',
          details: t('epdWizard.messages.error') as string,
        });
      } finally {
      }
    }
  };
  reader.readAsArrayBuffer(file);
};

export const useCreateDictionary = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: DictionaryCatalogItem) => createDictionary(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DICTIONARY_CATALOG] });
      }
    },
  });
};

export const useUpdateDictionary = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: DictionaryCatalogItem) => updateDictionary(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DICTIONARY_CATALOG] });
      }
    },
  });
};

export const useCreateDictionaryValue = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: DictionaryValue) => createDictionaryValue(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DICTIONARY_VALUES] });
      }
    },
  });
};

export const useUpdateDictionaryValue = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: DictionaryValue) => updateDictionaryValue(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DICTIONARY_VALUES] });
      }
    },
  });
};

export const useDeleteDictionaryValue = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteDictionaryValue(id),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DICTIONARY_VALUES] });
      }
    },
  });
};

export const useCancelUpdateEpd = (epdId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => cancelUpdateEpd(epdId),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
      }
    },
  });
};

export const useStartVerification = (epdId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => startVerification(epdId),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
      }
    },
  });
};

export const useCancelVerification = (epdId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => cancelVerification(epdId),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
      }
    },
  });
};

export const useRegisterEpd = (epdId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => registerEpd(epdId),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
      }
    },
  });
};

export const useDeregisterEpd = (epdId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => deregisterEpd(epdId),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
      }
    },
  });
};

export const useCancelEpdDeregistration = (epdId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => cancelEpdDeregistration(epdId),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
      }
    },
  });
};

export const useUnlockEpd = (epdId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => unlockEpd(epdId),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
      }
    },
  });
};

export const useLicenseeAdminVerificationApprove = (epdId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => licenseeAdminVerificationApprove(epdId),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
      }
    },
  });
};

export const useApproveVerification = (epdId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (approveModel: any) => approveVerification(epdId, approveModel),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
      }
    },
  });
};

export const usePublishEpd = (epdId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (versionId: string) => publishEpd(epdId, versionId),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
      }
    },
  });
};

export const useUnpublishEpd = (epdId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => unpublishEpd(epdId),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
      }
    },
  });
};

export const useNeedsVerification = (epdId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => needsVerification(epdId),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
      }
    },
  });
};

export const useLicenseeAdminVerificationDecline = (epdId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => licenseeAdminVerificationDecline(epdId),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
      }
    },
  });
};

export const useRegisterEditorial = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: RegisterEditorialModel) => registerEditorial(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
      }
    },
  });
};

export const useEditorialUpdating = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: RegisterEditorialModel) => editorialUpdating(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
      }
    },
  });
};

export const useCancelRegisterEditorial = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: RegisterEditorialModel) => cancelRegisterEditorial(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
      }
    },
  });
};

export const useApproveEditorial = (epdId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => approveEditorial(epdId),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
      }
    },
  });
};

/***********************************************************************/
const useGenericMutation = <TData = unknown, TVariables = void>(
  mutationFn: MutationFunction<TData, TVariables>,
  invalidateQueriesKeys: (string | unknown[])[]
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onMutate: () => {},
    onError: (error: any) => {
      console.error('Error occurred:', error);
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await Promise.all(
          invalidateQueriesKeys.map((key) => queryClient.invalidateQueries({ queryKey: Array.isArray(key) ? key : [key] }))
        );
      }
    },
  });
};

export const useCreateCpcrSpecificTableRow = () => {
  return useGenericMutation((data: any) => createCpcrSpecificTableRow(data), [[QUERY_KEYS.EPD_CPCR_SPECIFIC_DATA]]);
};

export const useDeleteCpcrSpecificTableRow = () => {
  return useGenericMutation((id: string) => deleteCpcrSpecificDataRow(id), [[QUERY_KEYS.EPD_CPCR_SPECIFIC_DATA]]);
};

export const useUpdateCpcrSpecificTableRow = (epdVersionId: string) => {
  return optimisticMutation(
    (data: ProductComponent) => updateCpcrSpecificTableRow(data),
    [QUERY_KEYS.EPD_CPCR_SPECIFIC_DATA, epdVersionId]
  );
};

export const useUpdateCpcrSpecificTableColumn = (epdVersionId: string) => {
  return optimisticMutation(
    (data: any) => updateCpcrSpecificTableColumn(data),
    [QUERY_KEYS.EPD_CPCR_SPECIFIC_DATA, epdVersionId]
  );
};
