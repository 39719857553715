import { Column, ColumnEvent } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import React, { useState } from 'react';
import {
  useCreateEpdProductPackagingMaterial,
  useDeleteProductPackagingMaterial,
  useUpdateEpdProductPackagingMaterial,
} from 'services/api/mutations';
import { useEpdBestProductPackagingMaterials, useEPDDefinitionInformation } from 'services/api/queries';
import { HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';
import { EPDContentDeclarationProductType, ProductPackagingMaterial } from 'types/types';

import { cellNumberEditor, cellTextEditor } from '../GridCellEditors';
import { DataTableStyled, GridButton } from '../StyledGrid';
import { ISO_21930_ID } from '../../constants';

const BestProductPackagingMaterialsGrid: React.FunctionComponent<{ epdVersionId: string}> = ({
  epdVersionId }) => {
  const [selectedRows, setSelectedRows] = useState<ProductPackagingMaterial[] | null>(null);

  const rows = useEpdBestProductPackagingMaterials(epdVersionId, EPDContentDeclarationProductType.BestProduct).data;
  const createMutation = useCreateEpdProductPackagingMaterial(EPDContentDeclarationProductType.BestProduct);
  const updateMutation = useUpdateEpdProductPackagingMaterial(epdVersionId, EPDContentDeclarationProductType.BestProduct);
  const deleteMutation = useDeleteProductPackagingMaterial();

  const epdDefinitionInformation = useEPDDefinitionInformation(epdVersionId).data;
  const selectedStandards = epdDefinitionInformation?.declaredStandards?.split(',');
  const isISO21930StandardSelected = selectedStandards?.some((x) => x === ISO_21930_ID);

  const handleAddRow = () => {
    createMutation.mutate({ epdVersionId, weight: 0, declareContentType: EPDContentDeclarationProductType.BestProduct });
  };

  const handleDeleteSelected = async () => {
    if (!selectedRows) return;
    await Promise.all(selectedRows.map((x) => deleteMutation.mutateAsync(x.id!)));
    setSelectedRows(null);
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <HeaderCaptionSemiBold className="white-space-nowrap">Packaging materials</HeaderCaptionSemiBold>
      <span>
        <GridButton title="Add" icon="pi pi-plus" onClick={handleAddRow} />{' '}
        <GridButton
          title="Delete"
          icon="pi pi-trash"
          onClick={handleDeleteSelected}
          disabled={!selectedRows || selectedRows.length === 0 || deleteMutation.isPending}
        />
      </span>
    </div>
  );

  const columnTotal = (columnName: keyof ProductPackagingMaterial) => {
    return () =>
      rows?.reduce((a, b: ProductPackagingMaterial) => {
        return a + (b[columnName] as number);
      }, 0);
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column className="p-row-total" />
        <Column className="p-row-total" footer="Total" />
        <Column className="p-row-total" footer={columnTotal('weight')} />
        <Column className="p-row-total" footer={columnTotal('weightPercent')} />
        <Column className="p-row-total" footer={columnTotal('biogenicCarbonWeight')} />
        {isISO21930StandardSelected && <Column className="p-row-total" footer={columnTotal('biogenicCO2Weight')} />}
      </Row>
      <Row style={{ borderTop: 'none' }}>
        <Column className="p-row-note" />
        <Column
          className="p-row-note"
          footer={
            <div>
              Note<sup>1</sup>
            </div>
          }
        />
        <Column
          className="p-row-note"
          footer={'1 kg biogenic carbon is equivalent to 44/12 kg of CO2'}
          colSpan={isISO21930StandardSelected ? 4 : 3}
        />
      </Row>
      <Row style={{ borderTop: 'none' }}>
        <Column className="p-row-note" />
        <Column
          className="p-row-note"
          footer={
            <div>
              Note<sup>2</sup>
            </div>
          }
        />
        <Column
          className="p-row-note"
          footer={
            <div>
              The value will be changed accordingly to the selected unit in Step 3:
              <br /> Option 1: if choosing declared unit - Biogenic material<sup>1</sup>, <b>kg C/declared unit</b>
              <br /> Option 2: if choosing functional unit - Biogenic material<sup>2</sup>, <b>kg C/product</b>
            </div>
          }
          colSpan={4}
        />
      </Row>
    </ColumnGroup>
  );

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    rowData[field] = newValue;
    updateMutation.mutate(rowData);
  };

  return (
    <DataTableStyled
      header={header}
      footerColumnGroup={footerGroup}
      value={rows}
      selectionMode="checkbox"
      selection={selectedRows!}
      onSelectionChange={(e) => setSelectedRows(e.value as ProductPackagingMaterial[])}
      dataKey="id"
      tableStyle={{ minWidth: '49rem' }}
      showGridlines
      editMode="cell"
    >
      <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
      <Column
        field="name"
        header="Material name"
        editor={(options) => cellTextEditor(options)}
        onCellEditComplete={onCellEditComplete}
      />
      <Column
        field="weight"
        header="Weight, kg"
        editor={(options) => cellNumberEditor(options)}
        onCellEditComplete={onCellEditComplete}
      />
      <Column
        field="weightPercent"
        header="Weight-% (versus the product)"
        editor={(options) => cellNumberEditor(options)}
        onCellEditComplete={onCellEditComplete}
      />
      <Column
        field="biogenicCarbonWeight"
        header={
          <span>
            Biogenic material<sup>1</sup>, kg C/declared unit or product<sup>2</sup>
          </span>
        }
        editor={(options) => cellNumberEditor(options)}
        onCellEditComplete={onCellEditComplete}
      />
      {isISO21930StandardSelected && (
        <Column
          field="biogenicCO2Weight"
          header={
            <span>
              Biogenic material kg CO<sup>2</sup>, eq./declared unit or product<sup>2</sup>
            </span>
          }
        />
      )}
    </DataTableStyled>
  );
};

export default BestProductPackagingMaterialsGrid;
