import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import React, { useMemo } from 'react';
import { useEpdProductComponents } from 'services/api/queries';
import { HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';
import { EPDContentDeclarationProductType, ProductComponent } from 'types/types';

import { DataTableStyled } from '../StyledGrid';
import { YES_NO } from '../../constants';

const ProductComponentsISO21930GridPdf: React.FunctionComponent<{
  epdVersionId: string;
  serviceIncludesProductInSystemBoundary?: string;
}> = ({ epdVersionId, serviceIncludesProductInSystemBoundary }) => {
  const rows = useEpdProductComponents(epdVersionId!, EPDContentDeclarationProductType.Average).data;

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <HeaderCaptionSemiBold className="white-space-nowrap">Product content</HeaderCaptionSemiBold>
    </div>
  );

  const columnTotal = (columnName: keyof ProductComponent) => {
    return useMemo(
      () =>
        rows?.reduce((a, b: ProductComponent) => {
          return a + (b[columnName] as number);
        }, 0),
      [columnName, rows?.values()]
    );
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column className="p-row-total" footer="Total" />
        <Column className="p-row-total" footer={columnTotal('weight')} />
        <Column className="p-row-total" footer={columnTotal('finalWeightPercent')} />
        <Column className="p-row-total" footer={columnTotal('biogenicWeightPercent')} />
        <Column className="p-row-total" footer={columnTotal('biogenicCarbonWeight')} />
        <Column className="p-row-total" footer={columnTotal('biogenicCO2Weight')} />
      </Row>
      <Row style={{ borderTop: 'none' }}>
        <Column
          className="p-row-note"
          footer={
            <div>
              Note<sup>1</sup>
            </div>
          }
        />
        <Column className="p-row-note" footer={'1 kg biogenic carbon is equivalent to 44/12 kg of CO2'} colSpan={5} />
      </Row>
    </ColumnGroup>
  );

  const isTableFilledIn = rows?.every((r) => r.weight) && rows?.every((r) => r.name);

  if (serviceIncludesProductInSystemBoundary === YES_NO.NO) {
    if (!rows?.some(() => true) || !isTableFilledIn) return null;
  }

  return (
    <DataTableStyled
      header={header}
      footerColumnGroup={footerGroup}
      value={rows}
      dataKey="id"
      tableStyle={{ minWidth: '49rem' }}
      showGridlines
      editMode="cell"
    >
      <Column field="name" header="Content name" />
      <Column field="weight" header="Weight, kg" />
      <Column field="finalWeightPercent" header="Post-consumer recycled material, weight-% of product" />
      <Column field="biogenicWeightPercent" header="Biogenic material, weight-% of product" />
      <Column
        field="biogenicCarbonWeight"
        header={
          <span>
            Biogenic material<sup>1</sup>, kg C/declared unit or product<sup>2</sup>
          </span>
        }
      />
      <Column
        field="biogenicCO2Weight"
        header={
          <span>
            Biogenic material kg CO<sup>2</sup>, eq./declared unit or product<sup>2</sup>
          </span>
        }
      />
    </DataTableStyled>
  );
};

export default ProductComponentsISO21930GridPdf;
