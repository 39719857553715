import React from 'react';

import { PDF_H1, PDF_P, PDF_PAGE, PDF_Table } from './Styles.styled';
import ElectricityGridPdf from 'components/v2/compiler/grids/electricity/ElectricityGridPdf';
import ContributionOfScrapInputsGridPdf from 'components/v2/compiler/grids/contribution-of-scrap-inputs/ContributionOfScrapInputsGridPdf';
import TransportationPerformanceGridPdf from 'components/v2/compiler/grids/specification/TransportationPerformanceGridPdf';
import {
  DataSourceOptionModel,
  EPDBasedOnUnitEnum,
  EPDPDFModel,
  ScrapInputsContributionLevelEnum,
  Option,
  EPDDataSourceModel,
} from 'types/types';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';
import { useEpdConversionFactor, useEpdDataSourceOptions, useEpdReferenceFlow } from 'services/api/queries';
import { YES_NO } from 'components/v2/compiler/constants';

const LCAInformationPage: React.FunctionComponent<{
  e: EPDPDFModel;
  getOptionsFromDictionary: any;
  dataSources: any;
  referenceFlowUnits: any;
}> = ({ e, getOptionsFromDictionary, dataSources, referenceFlowUnits }) => {
  const epdBasedOnUnitOptions = getOptionsFromDictionary(EPD_DICTIONARY_CODES.EPD_BASED_ON_UNIT);
  const scrapInputsContributionLevelOptions = getOptionsFromDictionary(EPD_DICTIONARY_CODES.SCRAP_INPUT_CONTRIBUTION_LEVEL);
  const dataSourceOptionsSource = useEpdDataSourceOptions().data;
  const referenceFlowData = useEpdReferenceFlow(e.generalInformation.epdVersionId!).data;
  const conversionFactorData = useEpdConversionFactor(e.generalInformation.epdVersionId!).data;

  const unitOptions = referenceFlowUnits.map((x: any) => {
    return {
      value: x.value,
      label: x.description,
    } as Option;
  });

  const getDataSourceVersionOptions = (dataSourceId?: string) => {
    if (!dataSourceId) {
      return [] as Option[];
    }

    const dataSource = dataSourceOptionsSource?.find(
      (x: DataSourceOptionModel) => x.id.toLowerCase() === dataSourceId.toLowerCase()
    );

    return dataSource?.dataSourceVersions;
  };

  const selectedEPDBasedOnUnit = epdBasedOnUnitOptions.find(
    (x: any) =>
      e.lcaSpecification?.epdBasedOnUnit &&
      x.value === Object.keys(EPDBasedOnUnitEnum).indexOf(e.lcaSpecification?.epdBasedOnUnit).toString()
  );

  const selectedScrapInputsContributionLevel = scrapInputsContributionLevelOptions.find(
    (x: any) =>
      e.lcaSpecification?.scrapInputsContributionLevel &&
      x.value ===
        Object.keys(ScrapInputsContributionLevelEnum).indexOf(e.lcaSpecification?.scrapInputsContributionLevel).toString()
  );

  const selectedReferenceFlowUnit = unitOptions.find(
    (x: Option) => x.value.toLowerCase() === referenceFlowData?.unitId?.toLowerCase()
  )?.label;

  const selectedConversionFactorUnit = unitOptions.find(
    (x: Option) => x.value.toLowerCase() === conversionFactorData?.unitId?.toLowerCase()
  )?.label;

  const referenceFlowName =
    e?.lcaSpecification?.epdBasedOnUnit === EPDBasedOnUnitEnum.DeclaredUnit
      ? 'Declared unit and reference flow'
      : 'Reference flow';

  const isTransportationTableEnabled = e?.pcrInformation?.cpcr?.name?.includes('c-PCR-008');

  return (
    <PDF_PAGE>
      <PDF_H1>LCA information</PDF_H1>
      <PDF_Table>
        <thead>
          <tr>
            <th style={{ width: '30%' }}></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>EPD based on declared or functional unit</td>
            <td>{selectedEPDBasedOnUnit?.label}</td>
          </tr>
          {e?.lcaSpecification?.epdBasedOnUnit === EPDBasedOnUnitEnum.FunctionalUnit && (
            <tr>
              <td>Functional unit description</td>
              <td>{e?.lcaSpecification?.functionalUnitDescription}</td>
            </tr>
          )}
          <tr>
            <td>{referenceFlowName}</td>
            <td>
              {referenceFlowData?.productFlowName}
              <br />
              {selectedReferenceFlowUnit}, {referenceFlowData?.value}
            </td>
          </tr>
          <tr>
            <td>Conversion factor to mass</td>
            <td>
              {selectedConversionFactorUnit}, {conversionFactorData?.value}
            </td>
          </tr>
          <tr>
            <td>Datasources used for this EPD</td>
            <td>
              {dataSources?.map((item: EPDDataSourceModel, index: number) => (
                <React.Fragment key={index}>
                  {
                    dataSourceOptionsSource?.find(
                      (x: DataSourceOptionModel) => x.id.toLowerCase() === item.dataSourceId?.toLowerCase()
                    )?.name
                  }
                  <br />
                  {
                    getDataSourceVersionOptions(item.dataSourceId)?.find(
                      (x: Option) => x.value.toLowerCase() === item?.dataSourceVersionId?.toLowerCase()
                    )?.label
                  }
                  {index < dataSources.length - 1 && (
                    <>
                      <br />
                      <br />
                    </>
                  )}
                </React.Fragment>
              ))}
            </td>
          </tr>
          <tr>
            <td>LCA Software</td>
            <td></td>
          </tr>
          <tr>
            <td>Additional information about the underlying LCA-based information</td>
            <td>{e?.lcaSpecification?.lcaBasedDescription}</td>
          </tr>
          <tr>
            <td>List of characterisation methods</td>
            <td>{e?.lcaSpecification?.characterisationMethods}</td>
          </tr>
          <tr>
            <td>Technology description including background system</td>
            <td>{e?.lcaSpecification?.technologyDescription}</td>
          </tr>
          <tr>
            <td>Scrap inputs contribution level</td>
            <td>{selectedScrapInputsContributionLevel?.label}</td>
          </tr>
          {e.lcaSpecification?.infrastructureOrGoodsUpstreamIncluding === YES_NO.NO &&
            !e.lcaSpecification?.infrastructureOrGoodsContributionLevel && (
              <tr>
                <td>Infrastructure and capital goods</td>
                <td>Excluded</td>
              </tr>
            )}
          {e.lcaSpecification?.infrastructureOrGoodsContributionLevel === YES_NO.YES && (
            <tr>
              <td>Infrastructure and capital goods</td>
              <td>
                Included and contributing more than 10% to the cradle-to-gate results for all environmental impact indicators
              </td>
            </tr>
          )}
          {e.lcaSpecification?.infrastructureOrGoodsContributionLevel === YES_NO.NO && (
            <tr>
              <td>Infrastructure and capital goods</td>
              <td>
                Included and contributing less than 10% to the cradle-to-gate results for all environmental impact indicators
              </td>
            </tr>
          )}
          <tr>
            <td>Detailed description of infrastructure and capital goods</td>
            <td>
              <PDF_P dangerouslySetInnerHTML={{ __html: e.lcaSpecification?.descriptionOfInfrastructureAndGoods ?? '' }} />
            </td>
          </tr>
        </tbody>
      </PDF_Table>
      <br />
      {e.lcaSpecification?.scrapInputsContributionLevel &&
        e.lcaSpecification?.scrapInputsContributionLevel === ScrapInputsContributionLevelEnum.MorePermissiblePercent && (
          <ContributionOfScrapInputsGridPdf epdVersionId={e.generalInformation.epdVersionId} />
        )}
      <div style={{ pageBreakBefore: 'always' }}>
        <ElectricityGridPdf epdVersionId={e.generalInformation.epdVersionId} />
        <br />
        <br />
        {isTransportationTableEnabled && (
          <TransportationPerformanceGridPdf epdVersionId={e.generalInformation.epdVersionId} />
        )}
      </div>
    </PDF_PAGE>
  );
};

export default LCAInformationPage;
