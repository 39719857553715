import WizardTextAreaComponent from 'components/v2/epd-wizard-inputs/WizardTextArea';
import { Column, ColumnEvent } from 'primereact/column';
import { Dropdown, DropdownChangeEvent, DropdownProps } from 'primereact/dropdown';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useCreateEpdScenarioModule,
  useCreateEpdScenarioModuleDefaultDescription,
  useDeleteEpdScenarioModule,
  useUpdateEpdScenarioModule,
  useUpdateEpdScenarioModuleDefaultDescription,
} from 'services/api/mutations';
import { useEpdScenarioModule } from 'services/api/queries';
import styled, { css } from 'styled-components';
import { HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';
import { Option, ScenarioModuleDefaultDescriptionModel, ScenarioModuleItem, ScenarioModuleRequest } from 'types/types';

import { cellTextEditor } from '../GridCellEditors';
import { DataTableStyled, GridButton, Placeholder } from '../StyledGrid';

type TProps = {
  epdVersionId: string;
  scenarioId: string;
  module: string;
  moduleName: string;
  unitOptions: Option[] | undefined;
  errors?: any;
};

const ModuleGrid = ({ epdVersionId, scenarioId, module, moduleName, unitOptions, errors }: TProps) => {
  const { t } = useTranslation();

  const [selectedRows, setSelectedRows] = useState<ScenarioModuleItem[] | null>(null);

  const scenarioModule = useEpdScenarioModule({ epdVersionId, scenarioId, module } as ScenarioModuleRequest).data;
  const createMutation = useCreateEpdScenarioModule();
  const updateMutation = useUpdateEpdScenarioModule(epdVersionId);
  const deleteMutation = useDeleteEpdScenarioModule(epdVersionId);

  const createDefaultDescriptionMutation = useCreateEpdScenarioModuleDefaultDescription();
  const updateDefaultDescriptionMutation = useUpdateEpdScenarioModuleDefaultDescription();

  const handleAddRow = () => {
    createMutation.mutate({ epdVersionId, scenarioId, module } as ScenarioModuleRequest);
  };

  const handleDefaultDescriptionChange = (_: any, val: any) => {
    if (scenarioModule?.defaultScenarioModuleDescription === null) {
      createDefaultDescriptionMutation.mutate({
        epdVersionId,
        scenarioId,
        module,
        defaultDescription: val,
      } as ScenarioModuleDefaultDescriptionModel);
      return;
    }

    updateDefaultDescriptionMutation.mutate({
      epdVersionId,
      scenarioId,
      module,
      defaultDescription: val,
    } as ScenarioModuleDefaultDescriptionModel);
  };

  const handleDeleteSelected = async () => {
    if (!selectedRows) return;
    await Promise.all(selectedRows.map((x) => deleteMutation.mutateAsync(x.id!)));
    setSelectedRows(null);
  };

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field } = e;
    rowData[field] = newValue;

    if (rowData.mandatory && rowData.description === rowData.placeholder) {
      rowData['description'] = t(
        `epdCompiler.step3.scenarioTab.epdScenarioModule.${module}.description.${rowData.placeholder}`
      );
    }

    updateMutation.mutate(rowData);
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <HeaderCaptionSemiBold className="white-space-nowrap">Scenario data</HeaderCaptionSemiBold>
      <span>
        <GridButton title="Add" icon="pi pi-plus" onClick={handleAddRow} />{' '}
        <GridButton
          title="Delete"
          icon="pi pi-trash"
          onClick={handleDeleteSelected}
          disabled={!selectedRows || selectedRows.length === 0 || deleteMutation.isPending}
        />
      </span>
    </div>
  );

  const descriptionBodyTemplate = (rowData: ScenarioModuleItem) => {
    return rowData?.mandatory ? (
      <DescriptionCell
        readonly={true}
        dangerouslySetInnerHTML={{
          __html: t(`epdCompiler.step3.scenarioTab.epdScenarioModule.${module}.description.${rowData.placeholder}`),
        }}
      />
    ) : (
      <DescriptionCell readonly={false}>
        {rowData?.placeholder && !rowData?.description ? (
          <Placeholder>
            {t(`epdCompiler.step3.scenarioTab.epdScenarioModule.${module}.description.${rowData.placeholder}`)}
          </Placeholder>
        ) : (
          rowData?.description ?? <Placeholder>Type here</Placeholder>
        )}
      </DescriptionCell>
    );
  };

  const valueBodyTemplate = (rowData: ScenarioModuleItem) => {
    return rowData?.value ? (
      rowData.value
    ) : rowData.placeholder ? (
      <Placeholder>
        {t(`epdCompiler.step3.scenarioTab.epdScenarioModule.${module}.value.${rowData.placeholder}`)}
      </Placeholder>
    ) : (
      <Placeholder>Type value as appropriate</Placeholder>
    );
  };

  const dropdownValueTemplate = (option: Option, props: DropdownProps) => {
    return option && option !== null ? (
      <div dangerouslySetInnerHTML={{ __html: option.label }} />
    ) : (
      <div>{props.placeholder}</div>
    );
  };

  const dropdownItemTemplate = (option: Option) => {
    return option && option !== null && <div dangerouslySetInnerHTML={{ __html: option.label }} />;
  };

  const unitBodyTemplate = (rowData: ScenarioModuleItem, dropdownOptions: Option[]) => {
    const options = [...dropdownOptions];

    return rowData && (rowData.unitRequired || !rowData.mandatory) ? (
      <Dropdown
        style={{ width: '100%', minWidth: '1rem', border: 'none' }}
        placeholder="Select"
        value={rowData.unitId}
        valueTemplate={dropdownValueTemplate}
        itemTemplate={dropdownItemTemplate}
        optionLabel="label"
        optionValue="value"
        options={options}
        onChange={(e: DropdownChangeEvent) => {
          rowData.unitId = e.value;
          updateMutation.mutate(rowData);
        }}
      />
    ) : (
      <CellContainer readonly={true}>&nbsp;</CellContainer>
    );
  };

  return (
    <>
      <WizardTextAreaComponent
        label={`Description of the default scenario in module ${module}`}
        name="defaultScenarioModuleDescription"
        value={scenarioModule?.defaultScenarioModuleDescription}
        onChanged={handleDefaultDescriptionChange}
        error={errors}
      />
      <ModuleDataTableStyled
        header={header}
        value={scenarioModule?.scenarioModuleItems}
        selectionMode="checkbox"
        selection={selectedRows!}
        onSelectionChange={(e) => setSelectedRows(e.value as ScenarioModuleItem[])}
        dataKey="id"
        showGridlines
        editMode="cell"
        required={
          !scenarioModule?.defaultScenarioModuleDescription && scenarioModule?.scenarioModuleItems.every((x) => !x.value)
        }
      >
        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} className="padding" />
        <Column
          field="description"
          header={moduleName}
          body={descriptionBodyTemplate}
          editor={(options) => cellTextEditor(options)}
          onCellEditComplete={onCellEditComplete}
          onBeforeCellEditShow={(event) => {
            if (event.rowData.mandatory) {
              event.originalEvent.preventDefault();
              return false;
            }
          }}
          style={{ width: '40%' }}
        />
        <Column
          field="value"
          header="Value"
          body={valueBodyTemplate}
          editor={(options) => cellTextEditor(options)}
          onCellEditComplete={onCellEditComplete}
          className="padding"
          style={{ width: '40%' }}
        />
        <Column
          field="unitId"
          header="Unit"
          body={(rowData: ScenarioModuleItem) => unitBodyTemplate(rowData, unitOptions ?? [])}
        />
      </ModuleDataTableStyled>
    </>
  );
};

const ModuleDataTableStyled = styled(DataTableStyled)`
  border-collapse: collapse;
  .p-datatable-table {
    height: 100%;
  }
  .p-datatable-tbody > tr > td {
    padding: 0;
  }
  .p-datatable-tbody > tr > td.padding,
  .p-datatable-tbody > tr.p-datatable-emptymessage > td {
    padding: 0.5rem 1rem;
  }
  && .p-inputtext.p-dropdown-label.p-placeholder {
    color: ${(props) => props.theme.colors.primaryBlack};
  }
  .p-checkbox.p-component.p-disabled > .p-checkbox-box.p-component {
    opacity: 1;
    border-color: ${(props) => props.theme.colors.elementsDropDown};
  }
`;

const CellContainer = styled.div<{ readonly: boolean }>`
  ${(props) =>
    props.readonly &&
    css`
      background-color: ${(props) => props.theme.colors.elementsFilledInput};
    `}
  padding: 0.5rem 1rem;
  height: 100%;
`;

const DescriptionCell = styled(CellContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default ModuleGrid;
