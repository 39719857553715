export const YES_NO = {
  YES: 'Yes',
  NO: 'No',
};

export const YesNoDataSource = [
  { value: YES_NO.YES, label: YES_NO.YES },
  { value: YES_NO.NO, label: YES_NO.NO },
];

export const ISO_21930_ID = '3';
