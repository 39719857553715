import { TextButton } from 'components/v2/buttons';
import { ExpandPanelIcon } from 'components/v2/icons';
import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import styled from 'styled-components';

type TProps = React.PropsWithChildren<{
  title: string;
  tooltip?: string | undefined;
  tooltipPosition?: any | undefined;
  isReadOnly: boolean;
  error?: any;
}>;
const GridPanel = ({ title, tooltip, tooltipPosition, isReadOnly, error, children }: TProps) => {
  const [visible, setVisible] = useState(false);
  const onMaximize = () => {
    setVisible(true);
  };

  return (
    <div style={{ display: 'grid' }}>
      {visible /* this is done intentionally despite dialog has its visibility - otherwise issue with page scrollbar or full screen size of the dialog*/ && (
        <MaximizedDialog
          header={title}
          visible={visible}
          maximizable={true}
          maximized={true}
          onHide={() => setVisible(false)}
        >
          {children}
        </MaximizedDialog>
      )}
      <ElementsContainer>
        <div style={{ display: 'flex', alignSelf: 'auto', alignItems: 'Center' }}>
          <Title>{title}</Title>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
          {error && <TooltipErrorIcon content={error || 'Required'} />}
        </div>

        <TextButton
          className="white-space-nowrap"
          style={{ cursor: 'pointer' }}
          label="Expand table"
          icon={ExpandPanelIcon}
          text
          onClick={onMaximize}
        />
      </ElementsContainer>
      <div style={{ overflowX: 'hidden' }}>{children}</div>
    </div>
  );
};

const MaximizedDialog = styled(Dialog)`
  & .p-dialog-header-maximize {
    display: none;
  }
  & .p-dialog-content {
    padding: 10px 30px 30px 30px;
  }
`;

const Title = styled.div`
  ${(props) => props.theme.typography.headerCaptionSemiBold}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.75rem 0rem;
  margin: 0;
`;

const ElementsContainer = styled.div`
  align-items: center;
  width: 100%;
  justify-content: space-between;
  min-height: 38px;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
`;

export default GridPanel;
