import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import { ColumnEvent } from 'primereact/column';
import { GridButton, HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';

export const columnTotal = (columnName: keyof any, rows: any) => {
  const total = rows?.reduce((a: number, b: any) => {
    const value = b[columnName] as number | undefined;
    return a + (value ? value : 0);
  }, 0);

  return Number.isInteger(total) ? total?.toString() : total?.toFixed(2);
};

export const headerTemplate = (
  isFilledIn: boolean | undefined,
  caption: string | undefined,
  handleAddRow: any,
  handleDeleteSelected: any,
  deleteDisabled: boolean | undefined,
  error?: any
) => (
  <div className="flex flex-wrap align-items-center justify-content-between gap-2">
    <div style={{ display: 'flex', alignSelf: 'auto', alignItems: 'Center' }}>
      <HeaderCaptionSemiBold className="white-space-nowrap">{caption}</HeaderCaptionSemiBold>
      {(error || !isFilledIn) && <TooltipErrorIcon content={error || 'Required'} />}
    </div>
    <span>
      <GridButton title="Add" icon="pi pi-plus" onClick={handleAddRow} />{' '}
      <GridButton title="Delete" icon="pi pi-trash" onClick={handleDeleteSelected} disabled={deleteDisabled} />
    </span>
  </div>
);

export const onCellEdit = (e: ColumnEvent, updateMutation: any) => {
  let { rowData, newValue, field, originalEvent: event } = e;
  rowData[field] = newValue;
  updateMutation.mutate(rowData);
};

export const mandatoryBodyTemplate = (value: any, error?: any) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' /*, position: 'relative'*/ }}>
      <span>{value}</span>
      {(error || !value) && <TooltipErrorIcon content={error || 'Required'} />}
    </div>
  );
};

export const validatedCellTemplate = (value: any, error?: any) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
      <span>{value}</span>
      {error && <TooltipErrorIcon content={error} />}
    </div>
  );
};

export const cellTotalTemplate = (fieldName: string, rows: any, errors?: any) =>
  validatedCellTemplate(columnTotal(fieldName, rows), errors?.[`${fieldName}Total`]);
