import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import styled from 'styled-components';

export const DataTableStyled = styled(DataTable)`
  border-collapse: 'collapse';
  .p-datatable-header {
    background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
    padding: 12px 1rem;
    align-items: center;
  }
  .p-datatable-thead > tr > th {
    ${(props) => props.theme.typography.contentTableTitleXs}
    background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
    padding: 0.5rem 1rem;
  }
  .p-datatable-tbody > tr > td,
  .p-datatable-tfoot > tr > td {
    ${(props) => props.theme.typography.contentTableBodyXs}
    padding: 0.5rem 1rem;
  }
  && tfoot > tr > td.p-row-total {
    font-weight: 600;
    background-color: ${(props) => props.theme.colors.elementsTableRow};
  }

  && tfoot > tr > td.p-row-note {
    font-weight: 400;
    background-color: ${(props) => props.theme.colors.elementsTableRow};
  }

  &&& .p-datatable-tbody > tr > td.p-editable-column.p-cell-editing {
    padding: 0;
  }

  & .p-checkbox .p-checkbox-box {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    border: 1px solid var(--Status-labels-Gray-text, #7f8081);
    & .p-checkbox-icon {
      font-size: 12px;
    }
  }

  & .p-checkbox {
    width: 12px;
    height: 12px;
  }
  & .p-datatable-tbody > tr.p-highlight {
    background-color: ${(props) => props.theme.colors.primaryOrange};
  }

  & .p-checkbox .p-checkbox-box.p-highlight {
    background-color: ${(props) => props.theme.colors.primaryOrange};
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }
  & .p-checkbox .p-checkbox-box.p-highlight:hover {
    background-color: ${(props) => props.theme.colors.primaryOrange};
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }
  && .p-checkbox-box.p-focus {
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }

  && .p-inputtext {
    font-size: 12px;
  }
  && .p-inputtext:enabled:focus {
    border-color: ${(props) => props.theme.colors.primaryOrange};
    box-shadow: 0 0 0 0.2rem rgba(231, 81, 19, 0.5);
    /* border: 0;
    box-shadow: none; */
  }

  && .p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    border-color: ${(props) => props.theme.colors.primaryOrange};
    box-shadow: 0 0 0 0.2rem rgba(231, 81, 19, 0.5);
  }
  && .p-dropdown-item {
    overflow: scroll;
  }
`;

export const GridButton = styled(Button)<{ disabled?: boolean }>`
  width: 24px;
  height: 24px;
  margin-left: 4px;
  &.p-button.p-button-icon-only {
    width: 24px;
  }
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.elementsButtonDisabled : props.theme.colors.primaryOrange} !important;
`;

export const Placeholder = styled.div`
  ${(props) => props.theme.fonts.textSmallDescription}
  color: ${(props) => props.theme.colors.primaryBlack50};
`;

export const ColumnHeaderInput = styled.input`
  width: 100%;
  border: 0;
  display: flex;
  ${(props) => (props.disabled ? props.theme.colors.elementsFilledInput : props.theme.colors.elementsInputBorder)};
  background-color: transparent;
  color: ${(props) => (props.disabled ? props.theme.colors.primaryBlack70 : props.theme.colors.black)};

  &:hover {
    border-color: ${(props) => (props.disabled ? 'transparent' : props.theme.colors.primaryOrange)};
    background-color: ${(props) => props.theme.colors.lightGray};
  }

  &:focus {
    border-color: ${(props) => (props.disabled ? 'transparent' : props.theme.colors.primaryOrange)};
  }

  &:focus-visible {
    background-color: ${(props) => props.theme.colors.primaryWhite};
    outline: 2px solid
      ${(props) => (props.disabled ? props.theme.colors.elementsInputBorder : props.theme.colors.primaryOrange)};
  }
`;
