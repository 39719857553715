import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import React from 'react';
import { useEpdProductPanelContent } from 'services/api/queries';
import { HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';
import { EPDContentDeclarationProductType, ProductPanelContent } from 'types/types';

import { DataTableStyled } from '../StyledGrid';

const PanelContentISO21930GridPdf: React.FunctionComponent<{ epdVersionId: string }> = ({ epdVersionId }) => {
  const rows = useEpdProductPanelContent(epdVersionId, EPDContentDeclarationProductType.Average).data;

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <HeaderCaptionSemiBold className="white-space-nowrap">Panel content</HeaderCaptionSemiBold>
    </div>
  );

  const columnTotal = (columnName: keyof ProductPanelContent) => {
    return () =>
      rows?.reduce((a, b: ProductPanelContent) => {
        return a + (b[columnName] as number);
      }, 0);
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column className="p-row-total" footer="Total" />
        <Column className="p-row-total" footer={columnTotal('weight')} />
        <Column className="p-row-total" footer={columnTotal('weightPercent')} />
        <Column className="p-row-total" footer={columnTotal('biogenicCarbonWeight')} />
        <Column className="p-row-total" footer={columnTotal('biogenicCO2Weight')} />
      </Row>
      <Row style={{ borderTop: 'none' }}>
        <Column className="p-row-note" />
        <Column
          className="p-row-note"
          footer={
            <div>
              Note<sup>1</sup>
            </div>
          }
        />
        <Column className="p-row-note" footer={'1 kg biogenic carbon is equivalent to 44/12 kg of CO2'} colSpan={4} />
      </Row>
    </ColumnGroup>
  );

  return (
    <DataTableStyled
      header={header}
      footerColumnGroup={footerGroup}
      value={rows}
      dataKey="id"
      tableStyle={{ minWidth: '49rem' }}
      showGridlines
    >
      <Column field="name" header="Material name" />
      <Column field="weight" header="Weight, kg" />
      <Column field="weightPercent" header="Weight-% (versus the product)" />
      <Column
        field="biogenicCarbonWeight"
        header={
          <span>
            Biogenic material<sup>1</sup>, kg C/declared unit or product<sup>2</sup>
          </span>
        }
      />
      <Column
        field="biogenicCO2Weight"
        header={
          <span>
            Biogenic material kg CO<sup>2</sup>, eq./declared unit or product<sup>2</sup>
          </span>
        }
      />
    </DataTableStyled>
  );
};

export default PanelContentISO21930GridPdf;
