import React from 'react';
import { PDF_H1, PDF_H2, PDF_P, PDF_PAGE } from './Styles.styled';
import ProductComponentsGridPdf from 'components/v2/compiler/grids/product-content/ProductComponentsGridPdf';
import PackagingMaterialsGridPdf from 'components/v2/compiler/grids/product-content/PackagingMaterialsGridPdf';
import DangerMaterialsGridPdf from 'components/v2/compiler/grids/product-content/DangerMaterialsGridPdf';
import { EPDPDFModel } from 'types/types';
import PanelContentGridPdf from 'components/v2/compiler/grids/product-content/PanelContentGridPdf';
import { ISO_21930_ID, YES_NO } from 'components/v2/compiler/constants';
import { useEPDDefinitionInformation } from 'services/api/queries';
import PackagingMaterialsISO21930GridPdf from 'components/v2/compiler/grids/product-content/PackagingMaterialsISO21930GridPdf';
import PanelContentISO21930GridPdf from 'components/v2/compiler/grids/product-content/PanelContentISO21930GridPdf';
import ProductComponentsISO21930GridPdf from 'components/v2/compiler/grids/product-content/ProductComponentsISO21930GridPdf';

const ContentDeclarationPage: React.FunctionComponent<{
  e: EPDPDFModel;
}> = ({ e }) => {
  const isPanelContentEnabled = true; //e?.pcrInformation?.cpcr?.name?.includes('c-PCR-014');
  const epdDefinitionInformation = useEPDDefinitionInformation(e?.generalInformation?.epdVersionId).data;
  const selectedStandards = epdDefinitionInformation?.declaredStandards?.split(',');
  const isISO21930StandardSelected = selectedStandards?.some((x) => x === ISO_21930_ID);

  return (
    <PDF_PAGE>
      <PDF_H1>Content declaration</PDF_H1>
      <br />
      {e.generalInformation.serviceIncludesProductInSystemBoundary === YES_NO.NO && (
        <>
          <PDF_H2>Explanation why content declaration is not relevant</PDF_H2>
          <PDF_P>{e.generalInformation.declarationIrrelevanceExplanation}</PDF_P>
        </>
      )}
      {isISO21930StandardSelected ? (
        <ProductComponentsISO21930GridPdf
          epdVersionId={e.generalInformation.epdVersionId}
          serviceIncludesProductInSystemBoundary={e.generalInformation.serviceIncludesProductInSystemBoundary}
        />
      ) : (
        <ProductComponentsGridPdf
          epdVersionId={e.generalInformation.epdVersionId}
          serviceIncludesProductInSystemBoundary={e.generalInformation.serviceIncludesProductInSystemBoundary}
        />
      )}
      {isPanelContentEnabled && (
        <>
          <br />
          <br />
          {isISO21930StandardSelected ? (
            <PanelContentISO21930GridPdf epdVersionId={e.generalInformation.epdVersionId} />
          ) : (
            <PanelContentGridPdf epdVersionId={e.generalInformation.epdVersionId} />
          )}
        </>
      )}
      <br />
      <br />
      {isISO21930StandardSelected ? (
        <PackagingMaterialsISO21930GridPdf
          epdVersionId={e.generalInformation.epdVersionId}
          serviceIncludesProductInSystemBoundary={e.generalInformation.serviceIncludesProductInSystemBoundary}
        />
      ) : (
        <PackagingMaterialsGridPdf
          epdVersionId={e.generalInformation.epdVersionId}
          serviceIncludesProductInSystemBoundary={e.generalInformation.serviceIncludesProductInSystemBoundary}
        />
      )}

      {e.generalInformation.hasDangerMaterials && (
        <>
          <br />
          <br />
          <DangerMaterialsGridPdf epdVersionId={e.generalInformation.epdVersionId} />
        </>
      )}
    </PDF_PAGE>
  );
};

export default ContentDeclarationPage;
